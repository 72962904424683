import { UPDATE_CART_CELLS, ADD_CELL_TO_CART, REMOVE_CELL_FROM_CART } from 'actions/cells';

export default (state = [], action) => {
	switch (action.type) {
		case UPDATE_CART_CELLS:
			return action.payload;
		case ADD_CELL_TO_CART:
			return [...state, action.payload];
		case REMOVE_CELL_FROM_CART:
			const cells = state.filter(p => p.id !== action.payload);
			return cells;
		default:
			return state;
	}
};