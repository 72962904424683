import { SELECT_CELLS, FETCH_CELL, TOGGLE_DRAGGABLE_CELL } from 'actions/cells';

export default (state=null, action) => {
	switch (action.type) {
		case SELECT_CELLS:
			return action.payload;
		case FETCH_CELL:
			return [action.payload];
		case TOGGLE_DRAGGABLE_CELL:
			const newState = [...state];
			newState[0].draggable = !newState[0].draggable;
			if (!!newState[0].draggable) {
				newState[0].old_lat = newState[0].lat;
				newState[0].old_lng = newState[0].lng;
			}
			else {
				delete newState[0].old_lat;
				delete newState[0].old_lng;
			}
			return newState;
		default:
			return state;
	}
};