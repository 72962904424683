export const SIGNAL_COLORS = {
    'excellent': "#00FF00",
    'good': "#9ACD32",
    'medium': "#FFD700",
    'weak': "#FF4500",
    'bad': "#FF0000"
};

export const SIGNAL_QUALITY = {
    'weak': -95,
    'medium': -85,
    'good': -75,
    'excellent': -65
};

export const getSignalColor = value => {
    if (value >= SIGNAL_QUALITY['excellent']) {
        return SIGNAL_COLORS['excellent'];
    }
    if (value >= SIGNAL_QUALITY['good']) {
        return SIGNAL_COLORS['good'];
    }
    if (value >= SIGNAL_QUALITY['medium']) {
        return SIGNAL_COLORS['medium'];
    }
    if (value >= SIGNAL_QUALITY['weak']) {
        return SIGNAL_COLORS['weak'];
    }
    if (value < SIGNAL_QUALITY['weak']) {
        return SIGNAL_COLORS['bad'];
    }
};