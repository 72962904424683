import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../Header';
import LeafletMap from './LeafletMap';
import Filters from '../Filters';
import Panel from '../cells/Panel';

import { fetchCurrentUser } from 'actions/auth';
import { fetchFiltersValues } from 'actions/filtersValues';


const MapContainer = () => {
	const showFilters = useSelector(state => state.mapControls.showFilters);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCurrentUser());
		dispatch(fetchFiltersValues());
	}, [dispatch]);

	return (
		<>
			<Header />
			<div className="d-flex">
				<div className={`map-container ${showFilters ? 'active-filters' : ''}`}>
					<LeafletMap />
				</div>
				<Panel />
			</div>
			<Filters />
		</>
	);
};

export default MapContainer;