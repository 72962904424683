import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const WrappedMultiselect = ({ multiselectRef, ...props }) => (
	<Multiselect {...props} ref={multiselectRef} />
);

WrappedMultiselect.defaultProps = {
	showCheckbox: true,
	closeOnSelect: false
};

export default WrappedMultiselect;