import { FETCH_CELLS, MOVE_CELL } from 'actions/cells';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_CELLS:
			return action.payload;
		case MOVE_CELL:
			let newState = { ...state };
			if (action.payload.id && action.payload.position) {
				newState.aggregations.geo.buckets = newState.aggregations.geo.buckets.map(bucket => {
					const hits = bucket.items.hits.hits.map(p => {
						if (p._source.id === action.payload.id) {
							return { ...p, _source: { ...p._source, lat: action.payload.position.lat, lng: action.payload.position.lng }};
						}
						return p;
					})
					let newBucket = bucket;
					newBucket.items.hits.hits = hits;
					return newBucket;
				})
			}
			return newState;
		default:
			return state;
	}
};