import * as services from 'services/api';

export const FETCH_PROVIDERS = 'FETCH_PROVIDERS';

export const fetchProviders = () => async dispatch => {
	const results = await services.fetchProviders();
	if(results) {
		dispatch(fetchProvidersAsync(results));
	}
};

const fetchProvidersAsync = providers => ({
	type: FETCH_PROVIDERS,
	payload: providers
});