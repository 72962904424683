import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import PasswordInput from '../common/PasswordInput';
import Toast from '../common/Toast';

import { updateCurrentUser } from '../../actions/auth';


const AccountModal = ({ show, onHide }) => {
	const currentUser = useSelector(state => state.auth.currentUser);
	const [firstname, setFirstname] = useState(currentUser.firstname);
	const [lastname, setLastname] = useState(currentUser.lastname);
	const [password, setPassword] = useState('');
	const [errorPassword, setErrorPassword] = useState();
	const [showToast, setShowToast] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if(!show) {
			setPassword('');
			setErrorPassword(null);
		}
	}, [show]);

	const handleChangeFirstname = (e) => {
		setFirstname(e.target.value);
	};

	const handleChangeLastname = (e) => {
		setLastname(e.target.value);
	};

	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if(password && password.length < 6) {
			setErrorPassword('Password too short (min 6 characters)');
		}
		else {
			setErrorPassword(null);
			dispatch(updateCurrentUser({ firstname, lastname, password }));
			onHide();
			setShowToast(true);
		}
	};

	const handleCloseToast = () => {
		setShowToast(false);
	};

	return (
		<>
			<Modal show={show} onHide={onHide} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>Firstname</Form.Label>
							<Form.Control type="text" value={firstname} onChange={handleChangeFirstname} />
						</Form.Group>
						<Form.Group>
							<Form.Label>Lastname</Form.Label>
							<Form.Control type="text" value={lastname} onChange={handleChangeLastname} />
						</Form.Group>
						<Form.Group>
							<Form.Label>New password</Form.Label>
							<PasswordInput value={password} onChange={handleChangePassword} isInvalid={!!errorPassword} />
							{errorPassword && <div className="invalid-feedback d-block">{errorPassword}</div>}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="light" onClick={onHide}>Close</Button>
					<Button variant="primary" onClick={handleSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
			<Toast variant="success" onClose={handleCloseToast} show={showToast} message="Account updated" />
		</>
	);
};

export default AccountModal;