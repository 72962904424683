import * as services from 'services/api';

export const SELECT_CELLS = 'SELECT_CELLS';
export const FETCH_CELLS = 'FETCH_CELLS';
export const FETCH_CELL = 'FETCH_CELL';
export const UPDATE_DISPLAYED_CELLS = 'UPDATE_DISPLAYED_CELLS';
export const UPDATE_CART_CELLS = 'UPDATE_CART_CELLS';
export const ADD_CELL_TO_CART = 'ADD_CELL_TO_CART';
export const REMOVE_CELL_FROM_CART = 'REMOVE_CELL_FROM_CART';
export const TOGGLE_DRAGGABLE_CELL = 'TOGGLE_DRAGGABLE_CELL';
export const MOVE_CELL = 'MOVE_CELL';

export const selectCells = cells => ({
	type: SELECT_CELLS,
	payload: cells
});

export const fetchCells = (filters) => async dispatch => {
	const results = await services.fetchCells(filters);
	if(results) {
		dispatch(fetchCellsAsync(results));
	}
};

const fetchCellsAsync = cells => ({
	type: FETCH_CELLS,
	payload: cells
});

export const fetchCell = (id) => async dispatch => {
	const result = await services.fetchCell(id);
	if(result) {
		dispatch(fetchCellAsync(result));
	}
};

const fetchCellAsync = cell => ({
	type: FETCH_CELL,
	payload: cell
});

export const moveCell = (id, position) => ({
	type: MOVE_CELL,
	payload: { id, position }
});

export const updateDisplayedCells = ids => ({
	type: UPDATE_DISPLAYED_CELLS,
	payload: ids
});

export const updateCartCells = cells => ({
	type: UPDATE_CART_CELLS,
	payload: cells
});

export const addCellToCart = cell => ({
	type: ADD_CELL_TO_CART,
	payload: cell
});

export const removeCellFromCart = id => ({
	type: REMOVE_CELL_FROM_CART,
	payload: id
});

export const toggleDraggableCell = () => ({
	type: TOGGLE_DRAGGABLE_CELL,
	payload: null
});