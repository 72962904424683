// Override Edit from leaflet-draw
import L from 'leaflet';

L.Edit.Circle = L.Edit.Circle.extend({
	_resize: function (latlng) {
		var moveLatLng = this._moveMarker.getLatLng();
		var radius;

		// Calculate the radius based on the version
		if (L.GeometryUtil.isVersion07x()) {
			radius = moveLatLng.distanceTo(latlng);
		} else {
			radius = this._map.distance(moveLatLng, latlng);
		}
		this._shape.setRadius(radius);

		if (this._map._editTooltip) {
			// TODO: get options set by dev and not default options
			const { options } = L.Draw.Circle.prototype;
			this._map._editTooltip.updateContent({
				text: L.drawLocal.edit.handlers.edit.tooltip.subtext + '<br />' + L.drawLocal.edit.handlers.edit.tooltip.text,
				subtext: L.drawLocal.draw.handlers.circle.radius + ': ' + L.GeometryUtil.readableDistance(radius, true, options.feet, options.nautic)
			});
		}

		this._shape.setRadius(radius);

		this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
	}
});

L.Edit.Rectangle = L.Edit.Rectangle.extend({
	_resize: function (latlng) {
		if (this._map._editTooltip) {
			// TODO: get options set by dev and not default options
			const { options } = L.Draw.Rectangle.prototype;
			const latLngs = this._shape._defaultShape ? this._shape._defaultShape() : this._shape.getLatLngs();
			const area = L.GeometryUtil.geodesicArea(latLngs);

			this._map._editTooltip.updateContent({
				text: L.drawLocal.edit.handlers.edit.tooltip.subtext + '<br />' + L.drawLocal.edit.handlers.edit.tooltip.text,
				subtext: L.GeometryUtil.readableArea(area, options.metric)
			});
		}

		var bounds;

		// Update the shape based on the current position of this corner and the opposite point
		this._shape.setBounds(L.latLngBounds(latlng, this._oppositeCorner));

		// Reposition the move marker
		bounds = this._shape.getBounds();
		this._moveMarker.setLatLng(bounds.getCenter());

		this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
	}
});