import { createControlComponent } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-pegman/leaflet-pegman.min.js';

const PegmanControl = createControlComponent(
	({ position }) => (
		new L.Control.Pegman({
			position,
			theme: 'leaflet-pegman-v3-small'
		})
	)
);

PegmanControl.defaultProps = {
	position: 'topright'
};

export default PegmanControl;