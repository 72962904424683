import React from 'react';
import Toast from 'react-bootstrap/Toast';

import Portal from './Portal';


const CustomToast = ({ message, variant, ...props }) => {
	let classname = '';
	switch(variant) {
		case 'success':
			classname = 'bg-success text-white';
			break;
		case 'danger':
			classname = 'bg-danger text-white';
			break;
		default:
			break;
	};

	return (
		<Portal>
			<Toast className={classname} delay={5000} autohide style={{ position: 'absolute', bottom: 0, left: 0 }} animation={false} {...props}>
				<Toast.Body>{message}</Toast.Body>
			</Toast>
		</Portal>
	);
};

export default CustomToast;