import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';


// TODO improve this
const PrivateRoute = ({ children, ...rest }) => {
	const auth = useSelector(state => state.auth);

	return (
		<Route {...rest} render={({ location }) => (
			auth.isAuthenticated === true || localStorage.getItem('token')
				? children
				: <Redirect to={{
					pathname: '/login',
					state: { from: location }
				}} />
		)} />
	);
};

export default PrivateRoute;