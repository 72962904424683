import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Portal from './Portal';


const ColorPicker = ({ defaultColor, presetColors, onChange }) => {
	const [color, setColor] = useState(defaultColor);
	const [showColorPicker, setShowColorPicker] = useState(false);

	const handleClick = () => {
		setShowColorPicker(prev => !prev);
	};

	const handleClose = () => {
		setShowColorPicker(false);
	};

	const handleChange = (color) => {
		setColor(color.hex);
		if (onChange) {
			onChange(color.hex);
		}
	};

	return (
		<div className="color-picker">
			<div className="swatch" onClick={handleClick}>
				<div style={{ backgroundColor: color }} />
			</div>
			{showColorPicker ? (
				<Portal>
					<div className="color-picker-cover" onClick={handleClose} />
					<div className="color-picker-popover">
						<SketchPicker color={color} onChange={handleChange} presetColors={presetColors} disableAlpha />
					</div>
				</Portal>
			) : null }
		</div>
	);
};

ColorPicker.defaultProps = {
	defaultColor: '#e4032b',
	presetColors: ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']
};

export default ColorPicker;