import { RESET_MAP } from 'actions/mapControls';
import { UPDATE_MAP_CONTROLS } from 'actions/mapControls';

const initialState = {
	center: null,
	viewport: null,
	reset: false,
	showFilters: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_MAP_CONTROLS:
			return { ...state, ...action.payload };
		case RESET_MAP:
			return { ...initialState, reset: true, showFilters: state.showFilters };
		default:
			return state;
	}
};