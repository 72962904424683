import React, { useRef, useEffect } from 'react';

import WrappedMultiselect from './WrappedMultiselect';


const CustomMultiselect = ({ id, onChange, variant, selectedValues, ...props }) => {
	const multiselectRef = useRef(null);

	let style = {};
	if (variant === 'black') {
		style = {
			searchBox: {
				borderColor: '#000'
			},
			optionContainer: {
				borderColor: '#000'
			}
		}
	}

	// Fix clear filters
	useEffect(() => {
		if (selectedValues.length === 0 && multiselectRef.current) {
			multiselectRef.current.resetSelectedValues();
		}
	}, [selectedValues]);

	const handleSelect = (selectedList) => {
		if (onChange) {
			onChange({ key: id, value: selectedList.map(item => item.id) });
		}
	};

	const handleRemove = (selectedList) => {
		if (onChange) {
			onChange({ key: id, value: selectedList.map(item => item.id) });
		}
	};

	return (
		<WrappedMultiselect
			multiselectRef={multiselectRef}
			id={id}
			onSelect={handleSelect}
			onRemove={handleRemove}
			placeholder="All"
			emptyRecordMsg="No options available"
			style={style}
			selectedValues={selectedValues}
			{...props}
		/>
	);
};

CustomMultiselect.defaultProps = {
	displayValue: "name",
	hidePlaceholder: true,
	avoidHighlightFirstOption: true,
	variant: null
};

export default CustomMultiselect;