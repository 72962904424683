import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';


const Portal = ({ children }) => {
	const portalRoot = document.getElementById("portal");
	const container = React.useMemo(() => document.createElement("div"), []);

	useEffect(() => {
		portalRoot.appendChild(container);

		return () => {
			container.remove();
		};
	});

	return createPortal(children, portalRoot);
};

export default Portal;