import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

import Control from '../../common/Control';

const Icon = (props) => (
	<svg viewBox="0 0 20 20" {...props}>
		<polygon fill="#333333" points="6,9 10,1 14,9" />
		<polygon fill="#CCCCCC" points="6,11 10,19 14,11" />
	</svg>
);

const RotationControl = ({ position }) => {
	const map = useMap();
	const [style, setStyle] = useState({});
	const [angle, setAngle] = useState(0);
	const [isRotating, setIsRotating] = useState(false);
	const buttonRef = useRef();

	const rotate = (newAngle) => {
		map.setBearing(newAngle);
	}

	const handleMouseDown = () => {
		window.addEventListener('mouseup', handleMouseUp, true);
		window.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		window.removeEventListener('mousemove', handleMouseMove, true);
		window.removeEventListener('mouseup', handleMouseUp, true);
	};

	const handleMouseMove = (e) => {
		const elCenter = [buttonRef.current.getBoundingClientRect().left + buttonRef.current.offsetWidth / 2, buttonRef.current.getBoundingClientRect().top + buttonRef.current.offsetHeight / 2];
		setAngle(Math.atan2(e.pageX - elCenter[0], - (e.pageY - elCenter[1])) * (180 / Math.PI));
		setIsRotating(true);
	};

	const handleReset = () => {
		if (!isRotating) {
			setAngle(0);
		}
		setIsRotating(false);
	};

	useEffect(() => {
		rotate(angle);
		setStyle({ transform: `rotate(${angle}deg)` });
	}, [angle]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Control position={position}>
			<button onClick={handleReset} onMouseDown={handleMouseDown} ref={buttonRef}>
				<Icon style={style} />
			</button>
		</Control>
	);
};

export default RotationControl;