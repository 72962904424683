export const RESET_MAP = 'RESET_MAP';
export const UPDATE_MAP_CONTROLS = 'UPDATE_MAP_CONTROLS';

export const resetMap = () => ({
	type: RESET_MAP,
	payload: null
});

export const updateMapControls = controls => ({
	type: UPDATE_MAP_CONTROLS,
	payload: controls
});
