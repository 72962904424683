// leaflet-draw v3 EditControl.js
import { PropTypes } from 'prop-types';
import Draw from 'leaflet-draw'; // eslint-disable-line
import isEqual from 'lodash-es/isEqual';
import React, { useRef } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import leaflet, { Map, Control } from 'leaflet';

import Edit from './Edit'; // eslint-disable-line

const eventHandlers = {
	onEdited: 'draw:edited',
	onDrawStart: 'draw:drawstart',
	onDrawStop: 'draw:drawstop',
	onDrawVertex: 'draw:drawvertex',
	onEditStart: 'draw:editstart',
	onEditMove: 'draw:editmove',
	onEditResize: 'draw:editresize',
	onEditVertex: 'draw:editvertex',
	onEditStop: 'draw:editstop',
	onDeleted: 'draw:deleted',
	onDeleteStart: 'draw:deletestart',
	onDeleteStop: 'draw:deletestop',
};

function EditControl(props) {
	const context = useLeafletContext();
	const drawRef = useRef();
	const propsRef = useRef(props);

	const onDrawCreate = (e) => {
		const { onCreated } = props;
		const container = context.layerContainer || context.map;
		container.addLayer(e.layer);
		onCreated && onCreated(e);
	};

	React.useEffect(() => {
		const { map } = context;
		const { onMounted } = props;

		for (const key in eventHandlers) {
			map.on(eventHandlers[key], (evt) => {
				let handlers = Object.keys(eventHandlers).filter(
					(handler) => eventHandlers[handler] === evt.type
				);
				if (handlers.length === 1) {
					let handler = handlers[0];
					props[handler] && props[handler](evt);
				}
			});
		}
		map.on(leaflet.Draw.Event.CREATED, onDrawCreate);
		drawRef.current = createDrawElement(props, context);
		map.addControl(drawRef.current);
		onMounted && onMounted(drawRef.current);

		return () => {
			const { map } = context;

			map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

			for (const key in eventHandlers) {
				if (props[key]) {
					map.off(eventHandlers[key], props[key]);
				}
			}

			map.removeControl(drawRef.current);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (
			isEqual(props.draw, propsRef.current.draw) &&
			isEqual(props.edit, propsRef.current.edit) &&
			props.position === propsRef.current.position
		) {
			return false;
		}
		const { map } = context;

		drawRef.current.remove(map);
		drawRef.current = createDrawElement(props, context);
		drawRef.current.addTo(map);

		const { onMounted } = props;
		onMounted && onMounted(drawRef.current);

		return null;
	}, [props.draw, props.edit, props.position]); // eslint-disable-line react-hooks/exhaustive-deps

	return null;
}

function createDrawElement(props, context) {
	const { layerContainer } = context;
	const { draw, edit, position } = props;
	const options = {
		edit: {
			...edit,
			featureGroup: layerContainer,
		},
	};

	if (draw) {
		options.draw = { ...draw };
	}

	if (position) {
		options.position = position;
	}

	return new Control.Draw(options);
}

EditControl.propTypes = {
	...Object.keys(eventHandlers).reduce((acc, val) => {
		acc[val] = PropTypes.func;
		return acc;
	}, {}),
	onCreated: PropTypes.func,
	onMounted: PropTypes.func,
	draw: PropTypes.shape({
		polyline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		polygon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		rectangle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		circle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		marker: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	}),
	edit: PropTypes.shape({
		edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		remove: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		poly: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		allowIntersection: PropTypes.bool,
	}),
	position: PropTypes.oneOf([
		'topright',
		'topleft',
		'bottomright',
		'bottomleft',
	]),
	leaflet: PropTypes.shape({
		map: PropTypes.instanceOf(Map),
		layerContainer: PropTypes.shape({
			addLayer: PropTypes.func.isRequired,
			removeLayer: PropTypes.func.isRequired,
		}),
	}),
};

export default EditControl;