import React from 'react'
import L from 'leaflet';
import { CircleMarker, LayerGroup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useDispatch } from 'react-redux';

import { selectCells } from 'actions/cells';


const createMarkerClusterIcon = cluster => {
	const count = cluster._childCount;
	let size;
	if (count < 10) {
		size = 30;
	} else if (count < 100) {
		size = 40;
	} else {
		size = 50;
	}

	return (
		L.divIcon({
			html: `<span>${count}</span>`,
			className: 'marker-cluster-custom',
			iconSize: L.point(size, size, true),
		})
	);
};

const CellMarkerList = ({ records }) => {
	const dispatch = useDispatch();

	const markers = records?.map(record => {
		const handleClick = () => {
			console.log(record);
			dispatch(selectCells([record]));
		};

		return (
			<CircleMarker
				key={record.id}
				center={[record.lat, record.lng]}
				weight={1}
				fillOpacity={1}
				fillColor="#1F2041"
				color='#FFF'
				radius={10}
				eventHandlers={{ click: handleClick }}
			/>
		);
	});

	return (
		<LayerGroup>
			<MarkerClusterGroup showCoverageOnHover={false} iconCreateFunction={createMarkerClusterIcon}>
				{markers}
			</MarkerClusterGroup>
		</LayerGroup>
	);
};

CellMarkerList.defaultProps = {
	records: []
};

export default CellMarkerList;