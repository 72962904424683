import { UPDATE_FILTERS, RESET_FILTERS } from 'actions/filters';

const initialState = {
	q: '',
	types: [],
	countries: [],
	providers: [],
	networks: [],
	carrier_names: [],
	bands: [],
	timedelta: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FILTERS:
			return { ...state, ...action.payload};
		case RESET_FILTERS:
			return { ...state, ...initialState, ...action.payload };
		default:
			return state;
	}
};