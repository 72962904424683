import * as services from 'services/api';

export const FETCH_WANQUALITY = 'FETCH_WANQUALITY';

export const fetchWanQuality = (filters) => async dispatch => {
	const results = await services.fetchWanQuality(filters);
	if(results) {
		dispatch(fetchWanQualityAsync(results));
	}
};

const fetchWanQualityAsync = cells => ({
	type: FETCH_WANQUALITY,
	payload: cells
});
