import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FeatureGroup } from 'react-leaflet';
import { PencilFill } from 'react-bootstrap-icons';

import Control from '../../common/Control';
import EditControl from '../../common/leaflet-draw/EditControl';

import { selectMarker } from '../../../actions/markers';
import { selectCells } from '../../../actions/cells';
import { createPinPointIcon } from '../../../services/utils';
import ColorPicker from '../../common/ColorPicker';

const DEFAULT_COLOR = '#e4032b';


const AnnotationsControl = ({ position }) => {
	const [drawingMode, setDrawingMode] = useState(false);
	const [color, setColor] = useState(DEFAULT_COLOR);
	const currentMarker = useSelector(state => state.currentMarker);
	const dispatch = useDispatch();

	const toggleDrawingMode = () => {
		setDrawingMode(prev => !prev);
	};

	const handleChangeColor = (color) => {
		setColor(color);
	};

	return (
		<FeatureGroup eventHandlers={{
			click: (e) => {
				if (e.layer.editing._marker) {
					const marker = { lat: e.latlng.lat, lng: e.latlng.lng, id: `l_${e.sourceTarget._leaflet_id}` };
					dispatch(selectCells([]));
					if (currentMarker && marker.id === currentMarker.id) {
						dispatch(selectMarker(null));
					}
					else {
						dispatch(selectMarker(marker));
					}
				}
			},
		}}>
			<Control position={position} className="leaflet-bar color-control">
				<button onClick={toggleDrawingMode}>
					<PencilFill />
					<span className="sr-only">Drawing mode</span>
				</button>
				{drawingMode && <ColorPicker onChange={handleChangeColor} defaultColor={DEFAULT_COLOR} /> }
			</Control>
			{drawingMode && (
				<EditControl
					position={position}
					draw={{
						polyline: { shapeOptions: { color }},
						polygon: { shapeOptions: { color }},
						rectangle: { shapeOptions: { color }},
						circle: { shapeOptions: { color }},
						marker: {
							icon: createPinPointIcon(color)
						},
						circlemarker: false
					}}
				/>
			)}
		</FeatureGroup>
	);
};

AnnotationsControl.defaultProps ={
	position: 'topleft'
};

export default AnnotationsControl;