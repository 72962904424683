import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { Fullscreen } from 'react-bootstrap-icons';

import Control from '../../common/Control';


const FullscreenControl = ({ position }) => {
	const [fullscreen, setFullscreen] = useState(false);
	const map = useMap();

	const toggleFullscreen = () => {
		setFullscreen(currentFullscreen => !currentFullscreen);
	};

	useEffect(() => {
		const elem = document.documentElement;

		if (fullscreen) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}

			setTimeout(() => {
				map.invalidateSize();
			}, 1000);
		}
		else if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}

			setTimeout(() => {
				map.invalidateSize();
			}, 1000);
		}
	}, [fullscreen]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Control position={position}>
			<button onClick={toggleFullscreen}><Fullscreen /></button>
		</Control>
	);
};

export default FullscreenControl;