import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { resetPassword } from '../../services/api';
import PasswordInput from '../common/PasswordInput';


const useQuery = () => {
	return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const query = useQuery();
	const history = useHistory();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password) {
			const token = query.get('token');
			const response = await resetPassword({ password, token });
			if (response.success) {
				history.push('/login');
			}
			else {
				setError(response.message);
			}
		}
	};

	return (
		<div className="login container-fluid vh-100">
			<div className="row h-100">
				<div className="col-md-6 offset-md-6 d-flex flex-column justify-content-center align-items-center">
					<div className="d-flex flex-column align-items-stretch">
						<img src="/img/logo.png" alt="GISWAN" className="img-fluid mb-5" />
						<h1 className="text-primary mb-5">Welcome to GISWAN</h1>
						<p><b>Reset password</b></p>
						{error ? (
							<p className="text-danger">{error}</p>
						) : (
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<label htmlFor="password" className="sr-only">Password</label>
									<PasswordInput placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="off" />
								</div>
								<button type="submit" className="btn btn-primary w-100 justify-content-center">Reset password</button>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;