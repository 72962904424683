import React, { useState } from 'react';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const PasswordInput = (props) => {
	const [type, setType] = useState('password');

	const handleToggle = (e) => {
		e.preventDefault();
		setType(currentType => currentType === 'text' ? 'password' : 'text');
	}

	return (
		<InputGroup>
			<FormControl {...props} type={type} />
			<InputGroup.Append>
				<Button variant="outline-secondary" onClick={handleToggle}>{type === 'password' ? <EyeSlashFill /> : <EyeFill /> }</Button>
			</InputGroup.Append>
		</InputGroup>
	);
}

export default PasswordInput;