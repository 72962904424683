import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import { updateDisplayedCells, selectCells, fetchCells } from '../../actions/cells';
import { updateFilters } from '../../actions/filters';
import { updateMapControls } from '../../actions/mapControls';

import { DEFAULT_CENTER, DEFAULT_ZOOM, TOOLTIP_THRESHOLD_ZOOM, TOOLTIP_THRESHOLD_MARKER_COUNT } from '../../services/settings';


const MapEvents = ({ setVisibleMarkersTooltips }) => {
	const mapControls = useSelector(state => state.mapControls);
	const currentCells = useSelector(state => state.currentCells);
	const [mapBounds, setMapBounds] = useState();
	const dispatch = useDispatch();
	const map = useMapEvents({
		moveend: () => handleMove(),
		zoomend: () => handleZoom()
	});
	let lastZoom;

	const countVisibleMarkers = () => {
		let markersCount = 0;
		map.eachLayer((layer) => {
			if (layer instanceof L.CircleMarker) {
				if (map.getBounds().contains(layer.getLatLng())) {
					markersCount++;
				}
			}
		});
		return markersCount;
	};

	const centerMap = (center, zoom) => {
		let bounds = new L.LatLngBounds();
		bounds.extend(center);
		map.fitBounds(bounds, { maxZoom: zoom });
	};

	const updateSearchFilters = () => {
		const zoom = map.getZoom();
		const bounds = map.getCircumscribedBounds();
		const ne = [bounds._northEast.lat, bounds._northEast.lng];
		const sw = [bounds._southWest.lat, bounds._southWest.lng];

		setMapBounds({ zoom, ne, sw });
	};

	const handleZoom = (e) => {
		const zoom = map.getZoom();
		if (zoom >= TOOLTIP_THRESHOLD_ZOOM) {
			const markersCount = countVisibleMarkers();

			if (markersCount >= TOOLTIP_THRESHOLD_MARKER_COUNT) {
				setVisibleMarkersTooltips(false);
			}
			else {
				setVisibleMarkersTooltips(true);
			}
		}
		else if (zoom < TOOLTIP_THRESHOLD_ZOOM && (!lastZoom || lastZoom >= TOOLTIP_THRESHOLD_ZOOM)) {
			setVisibleMarkersTooltips(false);
		}
		lastZoom = zoom;
	};

	const handleMove = () => {
		updateSearchFilters();

		if (map.getZoom() >= TOOLTIP_THRESHOLD_ZOOM) {
			const markersCount = countVisibleMarkers();

			if (markersCount >= TOOLTIP_THRESHOLD_MARKER_COUNT) {
				setVisibleMarkersTooltips(false);
			}
			else {
				setVisibleMarkersTooltips(true);
			}
		}
	};

	useEffect(() => {
		dispatch(fetchCells());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const timerId = setTimeout(() => {
			dispatch(updateFilters(mapBounds));
		}, 125);

		return () => {
			clearTimeout(timerId);
		};
	}, [mapBounds, dispatch]);

	useEffect(() => {
		if (map && mapControls.center) {
			centerMap(mapControls.center, 13);
		}
	}, [mapControls.center]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (map && mapControls.viewport) {
			map.fitBounds(mapControls.viewport);
		}
	}, [mapControls.viewport]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (map && mapControls.reset) {
			map.setView(new L.LatLng(DEFAULT_CENTER[0], DEFAULT_CENTER[1]), DEFAULT_ZOOM);
			// initBoundingBox();

			dispatch(updateMapControls({ reset: false }));
			dispatch(updateDisplayedCells([]));
			dispatch(selectCells([]));
		}
	}, [mapControls.reset]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setTimeout(() => {
			map.invalidateSize();
		}, 250);
	}, [mapControls.showFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentCells?.length === 0) {
			map.invalidateSize();
		}
	}, [currentCells]); // eslint-disable-line react-hooks/exhaustive-deps

	return null;
};

export default MapEvents;