import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCells } from 'actions/cells';


const CellPanel = () => {
	const currentCells = useSelector(state => state.currentCells);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(selectCells([]));
	};

	if (!currentCells) {
		return null;
	}

	return (
		<div className="panel p-3">
			<div className="d-flex justify-content-end mt-2">
				<button className="btn" onClick={handleClose}><img src="/img/icons/close.svg" alt="Close" /></button>
			</div>
			{currentCells.map(cell => (
				<div key={`cell_${cell.id}`}>
					<h4>Cell {cell.id}</h4>
					<p>Radio: {cell.radio}</p>
					<p>MCC: {cell.mcc}</p>
				</div>
			))}
		</div>
	);
};

export default CellPanel;