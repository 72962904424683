import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer, LayersControl, ZoomControl, WMSTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import MapEvents from './MapEvents';
import WanQualityMarkerList from './WanQualityMarkerList';
import WanQualityLegend from './WanQualityLegend';
import CellMarkerList from './CellMarkerList';
import AnnotationsControl from './controls/AnnotationsControl';
import FullscreenControl from './controls/FullscreenControl';
import RotationControl from './controls/RotationControl';
import PegmanControl from './controls/PegmanControl';

import { GOOGLE_API_KEY, DEFAULT_CENTER, DEFAULT_ZOOM, MAX_ZOOM } from '../../services/settings';
import { mapStyle } from '../../services/mapStyle';


const googleMapsProps = {
	apiKey: GOOGLE_API_KEY,
	googleMapsLoaderConf: {
		version: 'weekly',
		libraries: ['places']
	},
	maxZoom: MAX_ZOOM
};


const LeafletMap = props => {
	const [visibleMarkersTooltips, setVisibleMarkersTooltips] = useState(false);
	const wanquality = useSelector(state => state.wanquality);
	const cells = useSelector(state => state.cells);
	const layersControl = useRef(null);

	return (
		<MapContainer {...props} zoomControl={false}>
			<MapEvents setVisibleMarkersTooltips={setVisibleMarkersTooltips} />
			<FullscreenControl position="topright" />
			<PegmanControl position="topright" />
			<RotationControl position="topright" />
			<LayersControl position="bottomright" ref={layersControl}>
				<LayersControl.BaseLayer name="OpenStreetMap" checked>
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						maxZoom={MAX_ZOOM}
						maxNativeZoom={18}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="OpenStreetMap Black & White">
					<TileLayer
						attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
						maxZoom={MAX_ZOOM}
						maxNativeZoom={18}
					/>
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Google Maps Roadmap">
					<ReactLeafletGoogleLayer {...googleMapsProps} type='roadmap' styles={mapStyle} />
				</LayersControl.BaseLayer>
				<LayersControl.BaseLayer name="Google Maps Satellite">
					<ReactLeafletGoogleLayer {...googleMapsProps} type='satellite' />
				</LayersControl.BaseLayer>

				<LayersControl.Overlay name="Cadastre Belgium">
					<WMSTileLayer
						layers={2}
						format="image/png"
						transparent
						url="https://eservices.minfin.fgov.be/arcgis/services/R2C/PlanParcellaire/MapServer/WMSServer/"
						maxZoom={MAX_ZOOM}
					/>
				</LayersControl.Overlay>
				<LayersControl.Overlay name="Cadastre Luxembourg">
					<TileLayer minZoom={10} maxZoom={MAX_ZOOM} maxNativeZoom={21} url="http://wmts1.geoportail.lu/opendata/wmts/cadastre/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png" opacity={0.7} />
				</LayersControl.Overlay>
				<LayersControl.Overlay checked name="Annotations">
					<AnnotationsControl position="topright" />
				</LayersControl.Overlay>
				<LayersControl.Overlay checked name="Markers">
					<WanQualityMarkerList records={wanquality} visibleTooltips={visibleMarkersTooltips} />
				</LayersControl.Overlay>
				<LayersControl.Overlay checked name="Cells">
					<CellMarkerList records={cells} visibleTooltips={visibleMarkersTooltips} />
				</LayersControl.Overlay>
			</LayersControl>
			<ZoomControl position="bottomright" />
			<WanQualityLegend />
		</MapContainer>
	);
};

LeafletMap.defaultProps = {
	center: DEFAULT_CENTER,
	zoom: DEFAULT_ZOOM,
	maxZoom: MAX_ZOOM,
	rotate: true
};

export default LeafletMap;