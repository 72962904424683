import React from 'react';
import { useSelector } from 'react-redux';

import CellPanel from './CellPanel';
// import MarkerPanel from './MarkerPanel';


const Panel = () => {
	const currentCells = useSelector(state => state.currentCells);
	// const currentMarker = useSelector(state => state.currentMarker);

	// if (currentMarker) {
	// 	return <MarkerPanel />;
	// }

	if (!currentCells || currentCells.length === 0) {
		return null;
	}

	if (currentCells.length === 1) {
		return <CellPanel />;
	}
};

export default Panel;