import axios from 'axios';
import qs from 'qs';
import { API_URL } from './settings';


axios.interceptors.request.use(
	config => {
		if(!config.headers.Authorization) {
			const token = localStorage.getItem('token');
			if(token) {
				config.headers['Authorization'] = token;
			}
		}
		return config;
	},
	error => {
		Promise.reject(error)
	}
);

/* Account */
export const login = async (data) => {
	const response = await axios.post(`${API_URL}/account/login`, data);
	return response.data;
};

export const forgotPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/forget_password`, data);
	return response.data;
};

export const resetPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/reset_password`, data);
	return response.data;
};

export const fetchCurrentUser = async () => {
	const response = await axios.get(`${API_URL}/account`);
	return response.data;
};

export const updateAccount = async (data) => {
	const response = await axios.put(`${API_URL}/account`, data);
	return await response.data;
};

/* Wanquality */
export const fetchWanQuality = async (data) => {
	const queryData = Object.fromEntries(Object.entries(data).filter(([k, v]) => v?.length > 0));
	const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}`  : '';
	let response = await axios.get(`${API_URL}/wanquality/search${query}`);
	return response.data.results;
};

/* Cells */
export const fetchCells = async (data) => {
	// const queryData = Object.fromEntries(Object.entries(data).filter(([k, v]) => v?.length > 0));
	// const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}`  : '';
	// let response = await axios.get(`${API_URL}/cells/search${query}`);
	// FIXME: /cells didn't work properly but /cells/<id> does
	const response = await axios.get(`${API_URL}/cells?page_size=1000&mcc=206`);
	return response.data.results;
};

export const fetchCell = async (id) => {
	let response = await axios.get(`${API_URL}/cells/${id}`);
	return response.data;
};

export const fetchProviders = async () => {
	let { data } = await axios.get(`${API_URL}/providers`);
	return data;
};

export const createTicket = async (data) => {
	const response = await axios.post(`${API_URL}/tickets`, data);
	return response.data;
};

export const moveProperty = async (id, data) => {
	const response = await axios.post(`${API_URL}/cells/${id}/move`, data);
	return response.data;
};

/* Filters */
export const fetchBands = async () => {
	const response = await axios.get(`${API_URL}/wanquality/values/band`);
	return response.data.values;
};

export const fetchWanTypes = async () => {
	const response = await axios.get(`${API_URL}/wanquality/values/type`);
	return response.data.values;
};

export const fetchCarriers = async () => {
	const response = await axios.get(`${API_URL}/wanquality/values/carrier_name`);
	return response.data.values;
};
