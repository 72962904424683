import { combineReducers } from 'redux';
import auth from './auth';
import wanquality from './wanquality';
import currentCells from './currentCells';
import cells from './cells';
import displayedCells from './displayedCells';
import providers from './providers';
import filters from './filters';
import filtersValues from './filtersValues';
import mapControls from './mapControls';
import cartCells from './cartCells';
import currentMarker from './currentMarker';

export default combineReducers({
	auth,
	wanquality,
	currentCells,
	cells,
	displayedCells,
	providers,
	filters,
	filtersValues,
	mapControls,
	cartCells,
	currentMarker
});
