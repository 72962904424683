import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Accordion, Form } from 'react-bootstrap';

import Multiselect from './common/inputs/Multiselect';
// import MultiRangeSlider from './common/inputs/MultiRangeSlider';

import { updateFilters, resetFilters } from '../actions/filters';
import { updateMapControls } from '../actions/mapControls';
// import countriesOptions from '../services/countries';

const TIMEDELTA = [
	{ value: 1, label: "Yesterday" },
	{ value: 7, label: "Last 7 days" },
	{ value: 14, label: "Last 14 days" },
	{ value: 30, label: "Last month" },
	{ value: 60, label: "Last 2 months" },
	{ value: 90, label: "Last 3 months" },
	{ value: 180, label: "Last 6 months" },
	{ value: 365, label: "Last year" },
	{ value: 730, label: "Last 2 yeaars" },
	{ value: 1095, label: "Last 3 years" }
];

const Filters = () => {
	const filters = useSelector(state => state.filters);
	const filtersValues = useSelector(state => state.filtersValues);
	const showFilters = useSelector(state => state.mapControls.showFilters);
	// const [openAccordionType, setOpenAccordionType] = useState(false);
	const [openAccordionNetwork, setOpenAccordionNetwork] = useState(false);
	const dispatch = useDispatch();

	const carrierOptions = filtersValues?.carrier_names?.filter(carrier => carrier).map(carrier => ({ id: carrier, name: carrier }));

	const handleToggleVisible = () => {
		dispatch(updateMapControls({ showFilters: !showFilters }));
	};

	const handleUpdateFilters = ({ key, value }) => {
		dispatch(updateFilters({ [key]: value }));
	};

	const handleChangeCheckbox = (e) => {
		const value = e.target.value;
		const key = e.target.name;
		const values = [...filters[key]];

		if(e.target.checked) {
			values.push(value);
		}
		else {
			const index = values.indexOf(value);
			values.splice(index, 1);
		}
		dispatch(updateFilters({ [key]: values }));
	};

	// const handleChangeSignal = ({ min, max }) => {
	// 	// TODO: update filters
	// };

	const handleResetFilters = () => {
		dispatch(resetFilters());
	};

	return (
		<div className={`container-filters ${showFilters ? 'active' : ''}`}>
			<button className="btn-toggle btn-dark" onClick={handleToggleVisible}>
				{showFilters ? <ChevronLeft /> : <ChevronRight />}
			</button>
			<div className="filters container-fluid">
				<div className="row">
					<div className="col-md-12 text-right">
						<button className="btn btn-link text-dark" onClick={handleResetFilters}>Reset filters</button>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<Form.Group className="mb-3">
							<Form.Label>Date</Form.Label>
							<Form.Control as="select" value={filters?.timedelta}  onChange={(e) => handleUpdateFilters({ key: "timedelta", value: e.target.value })} id="timedelta">
								<option></option>
								{TIMEDELTA.map(day => (
									<option key={`timedelta_${day.value}`} value={day.value}>{day.label}</option>
								))}
							</Form.Control>
						</Form.Group>

						{/* <Accordion defaultActiveKey="0">
							<div className="d-flex justify-content-between align-items-center">
								<h3>Search type</h3>
								<Accordion.Toggle as="span" eventKey="0" onClick={() => setOpenAccordionType(!openAccordionType)}>
									{openAccordionType ? <ChevronDown /> : <ChevronUp />}
								</Accordion.Toggle>
							</div>
							<Accordion.Collapse eventKey="0">
								<>
									<div className="mb-3">
										<h4>Country</h4>
										<Multiselect
											id="countries"
											options={countriesOptions}
											onChange={handleUpdateFilters}
											selectedValues={countriesOptions.filter(item => filters.countries.includes(item.id))}
										/>
									</div>
									{filtersValues?.types?.length > 0 && (
										<div className="mb-3">
											<h4>Type</h4>
											{filtersValues?.types?.map(type => (
												<div className="custom-control" key={`type_${type}`}>
													<input className="form-check-input" type="checkbox" value={type} id={`type-${type}`} name="types" onChange={handleChangeCheckbox} />
													<label className="form-check-label" htmlFor={`type-${type}`}>{type}</label>
												</div>
											))}
										</div>
									)}
								</>
							</Accordion.Collapse>
						</Accordion> */}

						<Accordion defaultActiveKey="0">
							<div className="d-flex justify-content-between align-items-center">
								<h3>Network</h3>
								<Accordion.Toggle as="span" eventKey="0" onClick={() => setOpenAccordionNetwork(!openAccordionNetwork)}>
									{openAccordionNetwork ? <ChevronDown /> : <ChevronUp />}
								</Accordion.Toggle>
							</div>
							<Accordion.Collapse eventKey="0">
								<>
									{/* <div className="mb-3">
										<h4>Provider</h4>
										<Multiselect
											id="providers"
											options={providers}
											onChange={handleUpdateFilters}
											selectedValues={providers.filter(item => filters.providers.includes(item.id))}
										/>
									</div> */}
									{filtersValues?.carrier_names?.length > 0 && (
										<div className="mb-3">
											<h4>Carrier</h4>
											<Multiselect
												id="carrier_names"
												options={carrierOptions}
												onChange={handleUpdateFilters}
												selectedValues={carrierOptions.filter(item => filters.carrier_names.includes(item.id))}
											/>
										</div>
									)}
									{filtersValues?.bands?.length > 0 && (
										<div className="mb-3">
											<h4>Bands</h4>
											{filtersValues?.bands?.filter(band => band).map(band => (
												<div className="custom-control" key={`band_${band}`}>
													<input className="form-check-input" type="checkbox" value={band} id={`band-${band}`} name="bands" onChange={handleChangeCheckbox} checked={filters?.bands?.includes(band)} />
													<label className="form-check-label" htmlFor={`band-${band}`}>{band}</label>
												</div>
											))}
										</div>
									)}
									{filtersValues?.types?.length > 0 && (
										<div className="mb-3">
											<h4>Type</h4>
											{filtersValues?.types?.map(type => (
												<div className="custom-control" key={`type_${type}`}>
													<input className="form-check-input" type="checkbox" value={type} id={`type-${type}`} name="types" onChange={handleChangeCheckbox} checked={filters?.types?.includes(type)} />
													<label className="form-check-label" htmlFor={`type-${type}`}>{type}</label>
												</div>
											))}
										</div>
									)}
									{/* <div className="mb-3">
										<h4>Network</h4>
										<div className="custom-control" >
											<input className="form-check-input" type="checkbox" value="gsm" id="network-gsm" onChange={handleChangeNetwork} />
											<label className="form-check-label" htmlFor="network-gsm">GSM</label>
										</div>
										<div className="custom-control" >
											<input className="form-check-input" type="checkbox" value="umts" id="network-umts" onChange={handleChangeNetwork} />
											<label className="form-check-label" htmlFor="network-umts">UMTS</label>
										</div>
										<div className="custom-control" >
											<input className="form-check-input" type="checkbox" value="lte" id="type-network-lte" onChange={handleChangeNetwork} />
											<label className="form-check-label" htmlFor="type-network-lte">LTE</label>
										</div>
										<div className="custom-control" >
											<input className="form-check-input" type="checkbox" value="nr" id="type-network-nr" onChange={handleChangeNetwork} />
											<label className="form-check-label" htmlFor="type-network-nr">NR</label>
										</div>
									</div> */}
									{/* <div className="mb-3">
										<h4>Maximum signal</h4>
										<MultiRangeSlider
											min={-140}
											max={-40}
											unit="dBm"
											onChange={handleChangeSignal}
										/>
									</div> */}
								</>
							</Accordion.Collapse>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Filters;