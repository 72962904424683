import React from 'react';

import { SIGNAL_COLORS, SIGNAL_QUALITY } from 'services/wanquality';

const WanQualityLegend = () => (
    <div className="wanquality-legend">
        <p className="wanquality-legend-title d-none d-sm-block">Quality of signal</p>
        <div className="d-flex flex-fill flex-wrap justify-content-start justify-content-md-center">
            {Object.keys(SIGNAL_QUALITY).map((key, index) => {
                const nextKey = Object.keys(SIGNAL_QUALITY)[index+1];
                if (index === 0) {
                    return (
                        <div className="wanquality-legend-block" key={`legend-${index}`}>
                            <span className="wanquality-legend-block-color" style={{ backgroundColor: SIGNAL_COLORS[key] }} />
                            <p>{`< ${SIGNAL_QUALITY[nextKey]}`} dB</p>
                        </div>
                    );
                }

                return (
                    <div className="wanquality-legend-block" key={`legend-${index}`}>
                        <span className="wanquality-legend-block-color" style={{ backgroundColor: SIGNAL_COLORS[key] }} />
                        {nextKey ? (
                            <p>{SIGNAL_QUALITY[key]} to {SIGNAL_QUALITY[nextKey]} dB</p>
                        ) : (
                            <p>{`> ${SIGNAL_QUALITY[key]}`} dB</p>
                        )}
                    </div>
                );
            })}
        </div>
    </div>
);

export default WanQualityLegend;