import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Search as SearchIcon } from 'react-bootstrap-icons';

import { updateFilters } from '../actions/filters';
import { updateMapControls } from '../actions/mapControls';


let autoComplete;

const SearchBar = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const dispatch = useDispatch();
	const autoCompleteRef = useRef(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(updateFilters({ q: searchTerm }));
	};

	useEffect(() => {
		const timerId = setTimeout(() => {
			if (searchTerm.length >= 3 || searchTerm.length === 0) {
				dispatch(updateFilters({ q: searchTerm }));
			}
		}, 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchTerm, dispatch]);

	const handleScriptLoad = () => {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{ componentRestrictions: { country: ["be", "lu"] } }
		);
		autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
		autoComplete.addListener("place_changed", () => handlePlaceSelect());
	};

	const handlePlaceSelect = () => {
		const place = autoComplete.getPlace();
		if (place.geometry?.viewport) {
			setSearchTerm('');

			const viewport = [
				[place.geometry.viewport.getSouthWest().lat(), place.geometry.viewport.getSouthWest().lng()],
				[place.geometry.viewport.getNorthEast().lat(), place.geometry.viewport.getNorthEast().lng()]
			];
			dispatch(updateMapControls({ viewport }));
		}
	};

	const initAutoComplete = (func) => {
		if (typeof window.google != 'undefined') {
			func();
		}
		else {
			setTimeout(function () {
				(function (func) {
					initAutoComplete(func);
				})(func)
			}, 500);
		}
	}

	useEffect(() => {
		initAutoComplete(handleScriptLoad);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form onSubmit={handleSubmit}>
			<div className="input-group">
				<input
					type="text"
					className="form-control"
					aria-label="Search"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					ref={autoCompleteRef}
					placeholder=""
				/>
				<div className="input-group-append">
					<button className="btn btn-dark " type="button" onClick={handleSubmit}>
						<span className="d-lg-block d-md-none">Search</span>
						<span className="d-none d-md-block d-lg-none"><SearchIcon size={16} /></span>
					</button>
				</div>
			</div>
		</form>
	);
};

export default SearchBar;