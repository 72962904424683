import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { createElementHook, createControlHook } from '@react-leaflet/core';
import { Control, DomUtil, DomEvent } from 'leaflet';

const DumbControl = Control.extend({
	options: {
		className: "",
		onOff: "",
		handleOff: function noop() { }
	},

	onAdd() {
		var _controlDiv = DomUtil.create("div", this.options.className);
		DomEvent.disableClickPropagation(_controlDiv);
		return _controlDiv;
	},

	onRemove(map) {
		if (this.options.onOff) {
			map.off(this.options.onOff, this.options.handleOff, this);
		}

		return this;
	}
})

const createControl = (props, context) => {
	const instance = new DumbControl(props)
	return { instance, context: { ...context, overlayContainer: instance } }

};

const useControlElement = createElementHook(createControl)
const useControl = createControlHook(useControlElement)

const createLeafletControl = (useElement) => {
	const Component = (props, ref) => {
		const { instance } = useElement(props).current;

		const contentNode = instance.getContainer();
		return contentNode ? createPortal(<div className="custom-control">{props.children}</div>, contentNode) : null;
	}
	return forwardRef(Component);
};

export default createLeafletControl(useControl);