import React from 'react'
import { CircleMarker, LayerGroup, Tooltip } from 'react-leaflet';

import { getSignalColor } from '../../services/wanquality';

const WanQualityMarkerList = ({ records }) => {
	const markers = records.aggregations?.geo?.buckets.map(bucket => {
		const record = bucket.items.hits.hits[0]._source;
		const value = Math.round(bucket.rssi_avg.value);
		const color = getSignalColor(value);

		return (
			<CircleMarker
				key={record.id}
				center={[record.lat, record.lng]}
				weight={1}
				fillOpacity={1}
				fillColor={color}
				color='#FFF'
				count={bucket.doc_count}
				radius={10}
			>
				<Tooltip>{value} dB</Tooltip>
			</CircleMarker>
		);
	});

	return (
		<LayerGroup>
			{markers}
		</LayerGroup>
	);
};

WanQualityMarkerList.defaultProps = {
	records: []
};

export default WanQualityMarkerList;