import * as services from 'services/api';

export const FETCH_FILTERS_VALUES = 'FETCH_FILTERS_VALUES';

export const fetchFiltersValues = () => async dispatch => {
	const bands = await services.fetchBands();
	const types = await services.fetchWanTypes();
	const carrier_names = await services.fetchCarriers();

	if(bands || types || carrier_names) {
		dispatch(fetchFiltersValuesAsync({
			bands,
			types,
			carrier_names
		}));
	}
};

const fetchFiltersValuesAsync = filtersValues => ({
	type: FETCH_FILTERS_VALUES,
	payload: filtersValues
});